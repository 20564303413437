import React from "react";
import { graphql, Link } from "gatsby";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Rellax from "rellax";
import { Seo } from "../utils/Seo.js";

import Layout from "../components/Layout/index.js";

import frame1 from "../images/platforms/wordpress.png";
import frame2 from "../images/platforms/Figma.png";
import frame3 from "../images/platforms/Frame-1.png";
import frame4 from "../images/platforms/Frame-2.png";
import frame5 from "../images/platforms/Frame-3.png";
import frame6 from "../images/platforms/Frame-4.png";
import frame7 from "../images/platforms/Frame-5.png";
import frame8 from "../images/platforms/Frame-6.png";
import frame9 from "../images/platforms/Frame-7.png";
import frame10 from "../images/platforms/Frame-8.png";
import frame11 from "../images/platforms/Frame-9.png";
import frame12 from "../images/platforms/Frame-10.png";
import frame13 from "../images/platforms/Frame-11.png";
import frame14 from "../images/platforms/Frame-12.png";
import frame15 from "../images/platforms/Frame-13.png";
import frame16 from "../images/platforms/Frame-14.png";
import frame17 from "../images/platforms/Frame-15.png";
import frame18 from "../images/platforms/Frame-16.png";
import frame19 from "../images/platforms/Frame-17.png";
import frame20 from "../images/platforms/Frame-18.png";
import frame21 from "../images/platforms/Frame-19.png";
import frame22 from "../images/platforms/Frame-20.png";
import frame23 from "../images/platforms/Frame.png";
import frame24 from "../images/platforms/Shopify.png";
import frame25 from "../images/platforms/Sketch.png";
import frame26 from "../images/platforms/Squarespace.png";

const Services = () => {
  return (
    <Layout>
      <Seo
        title="eCommerce and Digital Development | Montebello.digital"
        desc="Solving complex problems for great clients."
      />
      <div className="agency-hero">
        <Container fluid className="agency-hero-container">
          <div className="hero-content">
            <h1 className="title">
              Solving complex problems for great clients.
            </h1>
          </div>
        </Container>
      </div>
      <section className="our-services our-services--page">
        <Container>
          <h4 className="pre-title">
            Our Services<span className="color-brand">.</span>
          </h4>
          <h3 className="title mt-0">Results matter, we deliver.</h3>
          <p className="para-large">
            We’re not an agency that’s going to pitch you every service in the
            book. We focus on finding out what your problem is, and solving it
            with data driven CPC advertising. We find that focusing on only a
            few things lets us deliver the quality and focus that no other
            agency can match.
          </p>
          <hr />
          <Row className="services-grid">
            <Col lg={{ span: 6 }} id="service-custom-software">
              <h4 className="title-medium">Custom Software Development</h4>
              <p>
                We bring your ideas to life with tailor-made software that
                solves your unique challenges. Get a solution that fits like a
                glove, enhancing your operations and boosting efficiency.
              </p>
            </Col>
            <Col lg={{ span: 6 }} id="service-webdesign">
              <h4 className="title-medium">Web Design & Development</h4>
              <p>
                Your website is your online storefront, and we make it inviting.
                Expect a design that not only looks great but also drives
                engagement and conversions.
              </p>
            </Col>
            <Col lg={{ span: 6 }} id="service-consulting">
              <h4 className="title-medium">Technology Consulting</h4>
              <p>
                Navigating the tech landscape can be tricky. Let us be your
                guide. We help you make the smartest tech choices for long-term
                success, so you can focus on your business.
              </p>
            </Col>
            <Col lg={{ span: 6 }} id="service-mobile-apps">
              <h4 className="title-medium">Mobile Application Development</h4>
              <p>
                Reach your audience wherever they are. With a custom mobile app,
                you provide convenience and a personal touch, boosting customer
                loyalty and growth.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="our-tools">
        <Container>
          <Row className="align-items-center">
            <Col md={{ span: 12, offset: 0 }}>
              <h3 className="pre-title">
                What we know<span className="color-brand">.</span>
              </h3>
              <h3 className="title mt-0">Tools of the trade.</h3>
              <p className="para-mega mb-5">
                Whatever platform you’re on we can help. We can integrate our
                solutions into an existing platform, or build something from
                scratch.
              </p>
            </Col>
            <Col md={{ span: 12, offset: 0 }}>
              <div className="our-tools--grid d-flex flex-wrap align-items-center justify-content-center">
                <img src={frame1} />
                <img src={frame25} />
                <img src={frame24} />
                <img src={frame4} />
                <img src={frame17} />
                <img src={frame5} />
                <img src={frame6} />
                <img src={frame22} />
                <img src={frame2} />
                <img src={frame8} />
                <img src={frame9} />
                <img src={frame10} />
                <img src={frame11} />
                <img src={frame23} />
                <img src={frame12} />
                <img src={frame26} />
                <img src={frame14} />
                <img src={frame15} />
                <img src={frame16} />
                <img src={frame18} />
                <img src={frame19} />
                <img src={frame20} />
                <img src={frame21} />
                <img src={frame3} />
                <img src={frame7} />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
};

export default Services;
